.grid-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    @media screen and (min-width: 320px) and (max-width: 767px) {
        display: block;
    }
    .mail-box {
        grid-column: 1/3;
    }
    .grid-btn {
        grid-column: 1/3;
    }
    // .m-0 {
    //     margin-bottom: 0px !important;
    //     @media screen and (min-width:320px) and (max-width:767px) {
    //         margin-bottom: 20px !important;
    //     }
    // }
    .single-form-field {
        margin-bottom: 0px !important;
        display: block !important;
        text-align: left;
    }
    .remember-me {
        margin: 0 !important;
    }
    span.text-danger {
        font-size: 14px;
    }
}
