.nft-container.solana {
    font-size: 20px;
    p.single-form-row {
        margin: 0 0 20px;
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-gap: 10px;
        @media screen and (min-width:320px) and (max-width:767px) {
            grid-template-rows: 1fr;
            grid-template-columns: 1fr;
            margin-bottom: 15px;
            
       }
        &:last-child {
            margin: 0;
            float: right;
        }
        input {
            height: 40px;
            line-height: 40px;
            padding: 0px 1rem;
            @media screen and (min-width:320px) and (max-width:767px) {
                height: 35px;
                width: 100%;
            }
        }
        label {
            margin: 0;
            font-size: 17px;
            padding: 0 15px 0 0;
            @media screen and (min-width:320px) and (max-width:767px) {
                font-size: 15px;
                padding: 0;
            }
        }
        textarea, .custom-selectbox {
            border-radius: 5px;
            border: 1px solid #909092;
            background-color: transparent;
            color: #fff;
        }
        .have-selectbox {
            .custom-selectbox {
                padding: 0px 1rem;
                width: 100%;
                height: 40px;
                @media screen and (min-width:320px) and (max-width:767px) {
                    height: 35px;
                    font-size: 15px;
                }
            }
        }
        .send-button-container {
            justify-self: end;
            grid-column: 2;
        }
    }
}
