.data-screen-container {
    margin: 0 auto;
    .have-selectbox {
        width: 150px;
        .custom-selectbox {
            border: 1px solid #909092;
            padding: 0.25rem 1rem;
            border-radius: 5px;
            color: #fff;
            font-size: 18px;
            background-color: transparent;
            height: 40px;
            @media screen and (min-width:320px) and (max-width:767px) {
                height: 35px;
                font-size: 15px;
            }
        }
    }
    input[type="file"] {
        border: none;
        padding: 0;
        color: #fff;
        @media screen and (min-width:320px) and (max-width:767px) {
            font-size: 15px;
        }
    }
    form {
        h3 {
        font-size: 18px;
        margin-bottom: 20px;
        }
        p.single-form-row {
            margin: 0 0 35px;
            display: grid;
            grid-template-columns: 100px 1fr;
            @media screen and (min-width:320px) and (max-width:767px) {
                 grid-template-rows: 1fr;
                 grid-template-columns: 1fr;
                 margin-bottom: 15px;
            }

            &:last-child {
                margin: 0;
            }
            label {
                min-width: 100px;
                margin: 0;
                font-size: 18px;
                padding: 0 15px 0 0;
                @media screen and (min-width:320px) and (max-width:767px) {
                    margin-bottom: 10px;
                    font-size: 15px;
                }
            }
            textarea {
                border-radius: 5px;
                border: 1px solid #909092;
                background-color: transparent;
                color: #fff;
            }
        }
        
        .btn-center {
            text-align: right;
        }
    }
    button.submit-button {
        justify-self: end;
        grid-column: 2;    
    }
    .table-container {
        .single-row {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 15px;
            margin: 0 0 12px;
            .form-container {
                margin: 12px 0;
                grid-column: 1 / 6;
            }
        }
    }
}

.off-chain-management {
    h3 {
        font-size: 16px;
        margin-bottom: 15px;
    }
    .off-chain-container {
        display: grid;
        grid-template-columns: 2fr 1fr 2fr;
        grid-gap: 20px;
        margin-bottom: 20px;
        @media screen and (min-width:320px) and (max-width:767px) {
            grid-template-rows: 2fr auto 2fr;
            grid-template-columns: 1fr;
            margin-bottom: 20px;
            justify-items: center;
        }
        ul {
            background-color: #fff;
            height: 200px;
            overflow-y: auto;
            border: 1px solid #999;
            border-radius: 4px;
            padding: 8px 0;
            margin: 0;
            @media screen and (min-width:320px) and (max-width:767px) {
                margin: 0;
                padding: 0;
                width: 200px;
            }
            li {
                text-align: left;
                margin: 0 0 5px;
                &:last-child {
                    margin: 0;
                }
                label {
                    position: relative;
                    color: #000;
                    display: block;
                    height: 35px;
                    line-height: 35px;
                    padding: 0;
                    margin: 0;
                    input[type="checkbox"] {
                        opacity: 0;
                        position: absolute;
                    }
                    input[type="checkbox"]:checked + span {
                        background-color: #d5d5d5;
                    }
                    span {
                        display: block;
                        padding: 0 12px;
                        cursor: pointer;
                        &:hover {
                            background-color: #d5d5d5;
                        }
                    }
                }
            }    
        }
    }
    .buttons-list {
        // padding: 12px 0 0;
        padding: 0;
        align-self: center;
        @media screen and (min-width:320px) and (max-width:767px) {
            padding: 0;
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-gap: 15px;
        }
        button {
            margin: 0 0 25px;
            @media screen and (min-width:320px) and (max-width:767px) {
                margin: 0;
                width: 200px;
            }
            &:last-child {
                margin: 0;
            }
        }
    }
    .save-button-container {
        text-align: right;
        // margin: 12px 0 0; 
        
        @media screen and (min-width:320px) and (max-width:767px) {
            // width: 120px;
            text-align: center;
        }
    }
}