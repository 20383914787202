.coming-soon {
  h2 {
    margin: 0 0 0.4em;
    padding: 0;
    color: #595959;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
    margin-top: 18px;
  }
  p {
    color: #545454;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    word-wrap: break-word;
  }
  button {
    border: 0;
    border-radius: 2px;
    background: initial;
    background-color: #3085d6;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    margin: 3px;
    padding: 6px 20px;
  }
}
