// .w-100 {
//     .modal-content {
//         width: auto;
//     }
// }
.p-50 {
    padding: 50px !important;
}

a {
    color: rgba(255, 255, 255, 0.55);
    &:hover {
        text-decoration: none !important;
    }
}
.container {
    // margin: 8vh 6vw;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        height: 66vh;
    }
    &.opened {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
    }
}

.lnks {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 10;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        background: -moz-radial-gradient(left, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
        background: -webkit-radial-gradient(
            left,
            ellipse cover,
            rgba(197, 202, 213, 0.7) 0%,
            rgba(255, 255, 255, 0) 70%
        );
        background: radial-gradient(ellipse at left, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
    }
}
.lnk,
.button {
    position: relative;
    float: left;
    width: 50%;
    height: 70px;
    line-height: 70px;
    font-size: 12px;
    color: #171717;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
}
.lnk:before,
.button:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background: -moz-radial-gradient(top, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(top, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at top, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
}
.lnk .text,
.button .text {
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}
.lnk:last-child:before,
.button:last-child:before {
    display: none;
}

.card-inner {
    padding: 0;
    position: absolute;
    overflow: hidden;
    left: 95%;
    top: 15px;
    width: 120%;
    height: 570px;
    background: #ffffff;
    transform-origin: center left;
    opacity: 0;
    visibility: hidden;
    z-index: 8;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    transition: visibility 1s linear, opacity 0.5s linear;
    -moz-transition: visibility 1s linear, opacity 0.5s linear;
    -webkit-transition: visibility 1s linear, opacity 0.5s linear;
    -o-transition: visibility 1s linear, opacity 0.5s linear;
    &.animated {
        opacity: 1;
        visibility: visible;
        z-index: 9;
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 20px;
        height: 20px;
        background: #ffffff;
        z-index: 10;
    }
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 20px;
        height: 20px;
        background: #ffffff;
        z-index: 10;
        top: auto;
        bottom: 0;
    }
    .card-wrap {
        padding: 30px 30px 30px 60px;
        position: relative;
        overflow-x: hidden;
        height: 100%;
    }
}
.content {
    margin: 0 0 60px 0;
    position: relative;
    &:last-child {
        margin-bottom: 0;
    }
    .title {
        margin: 0 0 15px 0;
        padding: 0 0 5px 0;
        position: relative;
        font-size: 17px;
        color: #171717;
        line-height: 21px;
        font-weight: 500;
        &:before {
            content: "";
            position: absolute;
            left: -12px;
            top: 0;
            width: 30px;
            height: 30px;
            background: -moz-linear-gradient(-45deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
            background: -webkit-linear-gradient(-45deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
            background: linear-gradient(135deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
            z-index: -1;
            border-radius: 30px;
            -moz-border-radius: 30px;
            -webkit-border-radius: 30px;
            -khtml-border-radius: 30px;
        }
        &:after {
            content: "";
            position: absolute;
            left: -60px;
            bottom: -15px;
            width: 95%;
            height: 1px;
            background: -moz-radial-gradient(
                left,
                ellipse cover,
                rgba(197, 202, 213, 0.7) 0%,
                rgba(255, 255, 255, 0) 70%
            );
            background: -webkit-radial-gradient(
                left,
                ellipse cover,
                rgba(197, 202, 213, 0.7) 0%,
                rgba(255, 255, 255, 0) 70%
            );
            background: radial-gradient(ellipse at left, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
        }
    }
}
::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}
.card-inner .card-wrap,
.content-sidebar .sidebar-wrap {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.lnks {
    display: flex;
    flex-direction: row;

    &:before {
        background: -moz-radial-gradient(center, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
        background: -webkit-radial-gradient(center, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
        background: radial-gradient(ellipse at center, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    }
}
.lnk,
.button,
input[type="submit"] {
    display: block;
    float: none;
    width: 100%;
    font-size: 14px;
    color: #323232;
    @media screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 12px;
    }
}
.lnk:before,
.button:before {
    background: -moz-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at top, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
}
.card-started {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    z-index: 11;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
        border-radius: 0 0 4px 0;
        -moz-border-radius: 0 0 4px 0;
        -webkit-border-radius: 0 0 4px 0;
        -khtml-border-radius: 0 0 4px 0;
    }
    .profile {
        // padding: 50px;
        position: relative;
        left: 0%;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        text-align: center;
        z-index: 10;
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        -khtml-border-radius: 4px;
        &.no-photo {
            // padding-top: 50px;
            .title {
                margin-top: 150px;
                font-size: 34px;
                @media screen and (min-width: 320px) and (max-width: 767px) {
                    font-size: 20px;
                    margin-top: 110px;
                }
                @media screen and (min-width: 768px) and (max-width: 1023px) {
                   font-size: 25px;
                   margin-top: 110px;
                }
            }
        }
        .slide {
            position: absolute;
            overflow: hidden;
            left: 0;
            top: 0;
            width: 100%;
            height: 245px;
            background-color: #626262;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            border-radius: 4px 4px 0 0;
            -moz-border-radius: 4px 4px 0 0;
            -webkit-border-radius: 4px 4px 0 0;
            -khtml-border-radius: 4px 4px 0 0;
            @media screen and (min-width:320px) and (max-width:767px ) {
                height: 190px;
            }
            @media screen and (min-width: 768px) and (max-width: 1023px) {
                height: 200px;
            }
            &:before {
                content: "";
                position: absolute;
                left: -15%;
                bottom: -63%;
                width: 100%;
                height: 70%;
                background: #ffffff;
                transform: rotate(12deg);
                -webkit-transform: rotate(12deg);
                -moz-transform: rotate(12deg);
                -o-transform: rotate(12deg);
            }
            &:after {
                content: "";
                position: absolute;
                bottom: -63%;
                width: 100%;
                height: 70%;
                background: #ffffff;
                transform: rotate(12deg);
                -webkit-transform: rotate(12deg);
                -moz-transform: rotate(12deg);
                -o-transform: rotate(12deg);
                left: auto;
                right: -25%;
                transform: rotate(-6deg);
                -webkit-transform: rotate(-6deg);
                -moz-transform: rotate(-6deg);
                -o-transform: rotate(-6deg);
            }
        }
    }
}
.card-started .profile .subtitle {
    position: relative;
    margin: 0;
    display: inline-block;
    color: #78cc6d;
    font-size: 16px;
    line-height: 20px;
    height: 20px;
    font-weight: 400;
    @media screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 13px;
    }
}
// .card-inner {
//     padding: 0;
//     position: absolute;
//     overflow: hidden;
//     left: 560px;
//     right: 0;
//     top: 15px;
//     bottom: 15px;
//     width: auto;
//     height: auto;
//     border-radius: 0 4px 4px 0;
//     -moz-border-radius: 0 4px 4px 0;
//     -webkit-border-radius: 0 4px 4px 0;
//     -khtml-border-radius: 0 4px 4px 0;
//     transition: visibility 1s linear, opacity 0s linear;
//     -moz-transition: visibility 1s linear, opacity 0s linear;
//     -webkit-transition: visibility 1s linear, opacity 0s linear;
//     -o-transition: visibility 1s linear, opacity 0s linear;
//     &:before,
//     &:after {
//         height: 30px;
//     }
//     .card-wrap {
//         padding: 30px 30px 0 30px;
//         position: relative;
//     }
// }
.content {
    margin: 0 0 40px 0;
    &:last-child {
        margin-bottom: 30px;
    }
    .title {
        margin: 0 0 0 0;
        padding: 0 0 30px 0;
        font-size: 20px;
        color: #323232;
        line-height: 21px;
        min-height: 51px;
        font-weight: 600;
        z-index: 2;
        .first-word,
        &::first-letter,
        .first-letter::first-letter {
            color: #78cc6d;
        }
        &:before {
            background: -moz-linear-gradient(-45deg, rgba(46, 202, 127, 0.15) 0%, rgba(46, 202, 127, 0.01) 100%);
            background: -webkit-linear-gradient(-45deg, rgba(46, 202, 127, 0.15) 0%, rgba(46, 202, 127, 0.01) 100%);
            background: linear-gradient(135deg, rgba(46, 202, 127, 0.15) 0%, rgba(46, 202, 127, 0.01) 100%);
        }
        &:after {
            left: -30px;
            right: 0;
            bottom: 0;
            width: auto;
            background: -moz-radial-gradient(left, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
            background: -webkit-radial-gradient(left, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
            background: radial-gradient(ellipse at left, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
        }
    }
}
::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.25);
}
.lnks:before {
    background: -moz-radial-gradient(left, ellipse cover, rgba(197, 202, 213, 0.15) 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(left, ellipse cover, rgba(197, 202, 213, 0.15) 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at left, rgba(197, 202, 213, 0.15) 0%, rgba(255, 255, 255, 0) 70%);
}
.lnk,
.button {
    color: rgba(255, 255, 255, 0.85);
}
.lnk:before,
.button:before {
    background: -moz-radial-gradient(top, ellipse cover, rgba(197, 202, 213, 0.15) 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(top, ellipse cover, rgba(197, 202, 213, 0.15) 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at top, rgba(197, 202, 213, 0.15) 0%, rgba(255, 255, 255, 0) 70%);
}
.card-started .profile {
    background: #31313a;
    .slide {
        &:before,
        &:after {
            background: #31313a;
        }
    }
    .title {
        color: rgba(255, 255, 255, 0.85);
    }
}
.card-inner {
    background: #31313a;
    &:before,
    &:after {
        background: #31313a;
    }
}
.content .title {
    color: rgba(255, 255, 255, 0.85);
    &:after {
        background: -moz-radial-gradient(left, ellipse cover, rgba(197, 202, 213, 0.15) 0%, rgba(255, 255, 255, 0) 70%);
        background: -webkit-radial-gradient(
            left,
            ellipse cover,
            rgba(197, 202, 213, 0.15) 0%,
            rgba(255, 255, 255, 0) 70%
        );
        background: radial-gradient(ellipse at left, rgba(197, 202, 213, 0.15) 0%, rgba(255, 255, 255, 0) 70%);
    }
    .first-word,
    &:first-letter {
        color: rgba(255, 255, 255, 0.85);
    }
}
