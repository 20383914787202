body {
    margin: 0;
    font-family: "Titillium Web", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url("../images/bg.gif");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top;
}

select,
input,
select {
    border: none;
    outline: none;
}
textarea {
    outline: none;
    min-height: 120px;
    padding: 10px;
}

a {
    text-decoration: none;
}
ul {
    padding: 0;
    li {
        list-style: none;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

button {
    text-decoration: none;
    border: none;
    background-color: transparent;
}
input {
    background-color: transparent;
}

.link {
    color: #1885c9;
    &:hover {
        color: #fff;
        cursor: pointer;
        transition: all 0.2s ease;
    }
}
.link-inverse {
    color: #fff;
    &:hover {
        color: #1885c9;
        cursor: pointer;
        transition: all 0.2s ease;
    }
}
.button {
    color: #fff;
    background-color: #1885c9;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    cursor: pointer;
    font-size: 1.15rem;
    transition: all 200ms ease-in;
    &:hover {
        background-color: #52b2ee;
    }
}
.button-inverse {
    color: #1885c9;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    cursor: pointer;
    font-size: 1.15rem;
    border: 1px solid #1885c9;
    transition: all 200ms ease-in;
    &:hover {
        background-color: #52b2ee;
        color: #fff;
    }
}

.custom-modal {
    .modal-dialog.modal-90w {
        width: 90%;
        max-width: none !important;
    }
    span.form-cross-icon {
        color: #fff;
        position: absolute;
        right: 20px;
        top: 10px;
        font-size: 24px;
        font-weight: 300;
        z-index: 10;
        cursor: pointer;
    }
    .modal-content {
        background-color: initial;
        .modal-body {
            padding: 0;
        }
    }
    .custom-ag-parent {
        height: 400px;
        width: 100%;
        margin: 25px 0 0;
    }
    .custom-ag-parent.ag-theme-alpine {
        .ag-root-wrapper {
            border-radius: 8px;
        }
    }
}
.custom-popup-component {
    .modal-content {
        .modal-body {
            background-color: rgba(28, 28, 33, 0.8);
            border-radius: 8px;
            padding: 2rem;
            position: relative;
        }
    }
}
// .custom-popup-component.light-custom-popup {
//     span.form-cross-icon {
//         color: #000 !important;
//     }
// }

.text-link {
    color: #1885c9;
    cursor: pointer;
}

option {
    color: #000;
}
.custom-form {
    background-color: hsla(240, 8%, 12%, 0.8);
    border-radius: 8px;
    padding: 2rem 3rem;
    text-align: center;
    position: relative;
    p,
    h2 {
        color: #fff;
    }
    h2 {
        font-size: 32px;
        margin: 0 0 15px;
    }
    .form-header {
        margin: 0 0 35px;
        p {
            margin: 0;
            font-size: 18px;
        }
    }
    .form-inputs {
        .remember-me {
            text-align: left;
            color: #fff;
            margin: 0 0 25px;
            label {
                cursor: pointer;
                margin: 0;
                input {
                    margin-right: 10px;
                }
            }
        }
        .forgot-password {
            text-align: left;
            label {
                margin: 0;
                cursor: pointer;
            }
        }
        .single-form-field {
            display: grid;
            margin: 0 0 25px;
            grid-template-rows: auto 1fr;
            // grid-gap: 5px;
            @media screen and (min-width: 320px) and (max-width: 767px) {
                margin: 0 0 20px;
            }
            label {
                // margin: 0 0 5px;
                color: #fff;
                display: block;
                text-align: left;
            }
            input {
                padding: 0 15px;
                height: 40px;
                border-radius: 4px;
                margin: 0;
                outline: none;
                border: none;
                background-color: #fff;
                width: 100%;
            }
            .have-icon {
                position: relative;
                display: grid;
                input {
                    padding-right: 40px;
                }
                .field-icon {
                    position: absolute;
                    right: 12px;
                    top: 50%;
                    transform: translate(0, -50%);
                    width: 20px;
                    cursor: pointer;
                }
            }
            span.text-danger {
                color: #dc3545 !important;
                display: inline-block;
                text-align: left;
            }
        }
        .single-form-field.has-error {
            input {
                border-color: #dc3545 !important;
            }
        }
    }
    button.submit-button {
        position: relative;
        grid-gap: 10px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        color: #fff;
        background-color: #1885c9;
        padding: 0.5rem 1rem;
        border-radius: 5rem;
        cursor: pointer;
        width: 60%;
        transition: all 200ms ease-in;
        margin: 0 auto;
        grid-column: 1/3;
        @media screen and (min-width:320px) and (max-width:767px) {
            width: 100%;
        }
        > div {
            position: absolute;
            right: 45px;
        }
    }
}
.sm-button {
    background-color: #1885c9;
    color: #fff;
    border-radius: 5rem;
    width: 120px;
    height: 35px;
    font-size: 16px;
    text-align: center;
}
.v-middle {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}
.v-h-middle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.have-selectbox {
    position: relative;
    .custom-selectbox {
        width: 150px;
        height: 30px;
        padding: 0 24px 0 10px;
        border: none;
        border-radius: 4px;
        -webkit-appearance: none;
        appearance: none;
        outline: none;
    }
    i.fa {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
        color: #fff;
    }
}
// .send-button-container {
//     background: #fff;
//     border-radius: 4px;
//     height: 30px;
//     width: 100px;
//     font-size: 16px;
// }
h1.single-heading {
        font-size: 30px;
        color: #fff;
        margin-bottom: 22px;
        text-align: left;
        font-weight: 500;
}

.cursor-pointer {
    cursor: pointer;
}

.seed-modal {
    padding: 20px;
    .heading {
        margin: 0 0 30px;
        h2 {
            font-size: 24px;
            margin: 0;
        }
    }
    .custom-form {
        background-color: initial;
        border-radius: 0;
        padding: 0;
        text-align: left;
        display: grid;
        grid-gap: 20px;
        margin: 0 0 30px;
        .form-field {
            display: flex;
            grid-gap: 25px;
            align-items: center;
            label {
                margin: 0;
            }
            input {
                width: 250px;
                height: 34px;
                border: 1px solid #e0e0e0;
                border-radius: 4px;
                padding: 0 8px;
            }
        }
    }
    .submit-button {
        max-width: 180px;
        min-width: 180px;
        margin: 0 !important;
    }
    .white-button {
        border: 1px solid #000;
        background-color: #fff !important;
        color: #000 !important;
    }
}


/* Custom Grid/Flex Css Start */
.d-grid {display: grid !important;}
.d-flex {display: flex !important;}
.grid-gap-20 {grid-gap: 20px !important}
.justify-content-end {justify-content: end !important;}
.justify-content-between {justify-content: between !important;}
/* Custom Grid/Flex Css End */

/* Custom Padding Css Start */
.p-0 {padding: 0 !important;}
/* Custom Padding Css Start */

/* Custom Border Radius Css Start */
.border-radius-0 {border-radius: 0 !important;}
/* Custom Border Radius Css End */