.popup-container {
    width: 100%;
    color: #fff;
    input {
        border: 1px solid #909092;
        font-size: 1.15rem;
        padding: 0.25rem 1rem;
        border-radius: 5px;
        color: #fff;
        // background-color: #fff;
    }
    .popup {
        background-color: rgba(28, 28, 33, 0.8);
        width: 500px;
        padding: 2rem;
        border-radius: 1rem;
        @media (max-width: 550px) {
            width: 100vw;
            height: 100vh;
        }
    }
    .popup-cancel {
        color: #fff;
        font-size: 1.5rem;
        width: max-content;
        transition: all 0.1s ease-in;
        &:hover {
            color: red;
        }
    }
    .popup-submit-button {
        margin-top: 2rem;
        width: 30%;
        position: relative;
        right: -70%;
        @media (max-width: 550px) {
            width: 100%;
            right: 0;
        }
    }
}
.seed-container {
    h1 {
        text-align: center;
    }
    .fs-34 {
        font-size: 34px;
    }
    .form-container {
        .single-form-row {
            margin-bottom: 20px;
            display: grid;
            grid-gap: 15px;
            label {
                margin: 0;
                @media screen and (min-width: 320px) and (max-width: 767px) {
                    font-size: 15px;
                }
                input{
                    width: 15px;
                }
            }
            label.radio-btn {
                display: grid;
                grid-template-columns: 15px 50px 15px 120px;
                grid-gap: 15px;
                align-items: center;
            }
            label.single-radio-btn {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: 15px;
                align-items: center;
            }
            input {
                width: 100%;
                height: 40px;
                padding: 0px 1rem;
                line-height: 40px;
                @media screen and (min-width: 320px) and (max-width: 767px) {
                    height: 35px;
                }
            }
            &:last-child {
                margin: 0;
            }
        }
        // .mb-30 {
        //     margin-bottom: 30px;
        // }
        .btn-right {
            justify-items: end;
            .btn-width {
                width: 150px;
            }
        }
    }
}


input:disabled {
    background-color: rgb(82,79,79,0.8);
}