.main-container {
    header {
        height: 60px;
        .nav {
            // display: flex;
            // justify-content: space-between;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            padding: 0px 16px;
            position: relative;
            z-index: 10;
            height: inherit;
            @media screen and (min-width: 320px) and (max-width: 767px) {
                grid-template-rows: 1fr;
                grid-template-columns: auto 1fr;
            }

            .nav-left {
                // display: flex;
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: 20 px;
                align-items: center;
                grid-gap: 20px;
                backdrop-filter: blur(2px);
                @media screen and (min-width: 320px) and (max-width: 767px) {
                    grid-gap: 15px;
                }
                .nav-menu-btn {
                    position: relative;
                    display: grid;
                    place-content: center;
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    .nav-menu-btn-burger {
                        width: 25px;
                        height: 2px;
                        background: #fff;
                        transition: all 0.3s ease-in-out;
                        &::before,
                        &::after {
                            content: "";
                            position: absolute;
                            width: 25px;
                            height: 2px;
                            background: #fff;
                            transition: all 0.3s ease-in-out;
                        }
                        &::before {
                            transform: translateY(-8px);
                        }
                        &::after {
                            transform: translateY(8px);
                        }
                    }
                }
                img.nav-logo {
                    width: 100px;
                    @media screen and (min-width:320px) and (max-width:767px) {
                        width: 60px;
                        height: 60px;
                    }
                }
                .nav-menu-btn.nav-menu-open {
                    .nav-menu-btn-burger {
                        background: transparent;
                        box-shadow: none;
                        &::before {
                            transform: rotate(45deg);
                        }
                        &::after {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
            .nav-right {
                display: flex;
                justify-content: end;
                grid-gap: 20px;
                @media screen and (min-width: 320px) and (max-width: 767px) {
                    grid-gap: 15px;
                }
                ul {
                    margin: 0;
                    li {
                        display: inline-block;
                        margin-right: 40px;
                        position: relative;
                        @media screen and (min-width: 320px) and (max-width: 767px) {
                            margin-right: 14px;
                        }
                        &:last-child {
                            margin: 0;
                        }
                        &:hover {
                            ul.inner-menu {
                                display: block;
                            }
                        }
                        a {
                            padding: 0;
                            display: block;
                            color: #fff;
                            text-transform: uppercase;
                            font-weight: 500;
                            font-size: 16px;
                            cursor: pointer;
                            text-decoration: none;
                            @media screen and (min-width: 320px) and (max-width: 767px) {
                                font-size: 14px;
                            }
                            &:hover {
                                color: #1885c9;
                                transition: all 0.2s ease;
                            }
                        }
                        ul.inner-menu {
                            display: none;
                            position: absolute;
                            text-align: left;
                            background-color: rgba(0, 0, 0, 0.88);
                            top: 34px;
                            right: 0;
                            width: 145px;
                            height: initial;
                            padding: 8px 0;
                            li {
                                display: block;
                                margin: 0 0 8px;
                                &:last-child {
                                    margin: 0;
                                }
                                a {
                                    font-size: 14px;
                                    padding: 0 12px;
                                    &:hover {
                                        background-color: #4440bc;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                    li.have-avatar {
                        width: 2em;
                        color: #fff;
                        position: relative;
                        img {
                            filter: brightness(0) invert(1);
                            width: 2em;
                            height: 2em;
                            border: 1px solid #fff;
                            border-radius: 5rem;
                        }
                    }
                }
            }
        }

        .user-profile-container {
            position: relative;
            p.username {
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                text-transform: uppercase;
                width: 40px;
                height: 40px;
                background-color: #fff;
                border-radius: 50%;
                border: 1px solid #e0e0e0;
                cursor: pointer;
            }
            .user-profile-dropdown {
                position: absolute;
                right: 0;
                width: 180px;
                background-color: #fff;
                border-radius: 6px;
                padding: 15px 0;
                top: 48px;
                box-shadow: 0 2px 8px rgba(0,0,0,.15);
                min-width: 280px;
                li {
                    height: 40px;
                    line-height: 40px;
                    padding: 0 20px;
                    text-align: left;
                    display: block !important;
                    cursor: pointer;
                    margin: 0 !important;
                    color: #53535f;
                    &:hover {
                        background-color: #fafafa;
                        color: #2a2a2a;
                    }
                    &:first-child {
                        height: auto;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #edf2f9!important;
                        margin-bottom: 10px !important;
                        &:hover {
                            color: inherit;
                            background-color: initial;
                        }
                    }
                    .user-info {
                        display: grid;
                        grid-template-columns: 40px 1fr;
                        grid-gap: 15px;
                        p.username {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #141b38;
                            color: #fff;
                            font-size: 16px;
                        }
                        p {
                            margin: 0;
                            span {
                                display: block;
                                line-height: 22px;
                                color: #2a2a2a !important;
                                font-size: 14px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                width: 185px;
                                white-space: nowrap;
                            }
                            span.email {
                                color: #72849a;
                            }
                        }
                    }
                }
            }
        }
    }

    .sidebar {
        background-color: rgba(0, 0, 0, 0.88);
        height: 100vh;
        text-transform: uppercase;
        position: fixed;
        top: 0;
        left: -50%;
        transition: left 0.3s ease-in;
        z-index: 2;
        @media (min-width: 320px) and (max-width: 767px) {
            left: -120%;
        }
        .sidebar-list {
            padding-top: 70px;
            width: 350px;
            overflow-y: auto;
            height: 100%;
            li {
                a {
                    padding: 0 20px;
                    cursor: pointer;
                    line-height: 32px;
                    display: block;
                    color: #1885c9;
                    text-decoration: none;
                    &:hover {
                        color: #fff;
                        background-color: #4440bc;
                    }
                }
                ul.sidebar-inner-menu {
                    transition: max-height 0.5s;
                    overflow: hidden;
                    max-height: 0;
                    li {
                        margin: 0 0 5px;
                        &:last-child {
                            margin: 0;
                        }
                        a {
                            line-height: 22px;
                            padding: 0 40px;
                            text-transform: capitalize;
                        }
                        a.disabled {
                            color: gray;
                            background: none;
                            cursor: not-allowed;
                        }
                    }
                }
                ul.sidebar-inner-menu.show {
                    max-height: 1000px;
                }
            }
        }
    }
    .sidebar.sidebar-show {
        left: 0;
    }

    .side-nav {
        background-color: rgba(0, 0, 0, 0.88);
        height: 100vh;
        text-transform: uppercase;
        position: fixed;
        top: 0;
        left: -50%;
        transition: left 0.3s ease-in;
        z-index: 2;
        @media (min-width: 320px) and (max-width: 767px) {
            left: -100%;
        }
        .side-nav-list {
            padding-top: 70px;
            max-width: 350px;
            min-width: 350px;
            overflow-y: auto;
            height: 100%;
            a {
                color: #1885c9;
                text-decoration: none;
            }
            .side-nav-menu {
                padding: 0 4rem;
                letter-spacing: 1px;
                line-height: 40px;
                height: 40px;
                &:hover {
                    background-color: #4440bc;
                }
            }
            .side-nav-submenu {
                li {
                    margin: 0 0 8px;
                    a {
                        font-size: 14px;
                        display: block;
                        min-height: 24px;
                        line-height: 24px;
                        padding: 0 2rem 0 5rem;
                        &:hover {
                            color: #fff;
                            background-color: #4440bc;
                        }
                    }
                }
            }
        }
    }
    .side-nav-show {
        left: 0;
    }
}

.popup-container.default-popup {
    display: initial;
    position: initial;
}

.modal-90w {
    width: 90vw;
    max-width: 90vw;
}
