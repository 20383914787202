.custom-contact-modal {
    .modal-dialog {
        width: 90% !important;
        max-width: none !important;
        h2 {
            margin-bottom: 20px;
        }
        .contact-Popup {
            .custom-ag-parent {
                margin-bottom: 20px;
                height: 320px;
            }
            .button-bar {
                display: grid;
                grid-template-columns: auto auto auto auto 1fr;
                grid-gap: 20px;
                @media screen and (min-width:320px) and (max-width:767px) {
                    grid-template-columns: 1fr;
                    // justify-items: center;
                }
                @media screen and (min-width:768px) and (max-width:1023px) {
                    grid-gap: 10px;
                }
                button {
                    @media screen and (min-width:320px) and (max-width:767px) {
                        width: 100%;
                    } 
                    @media screen and (min-width:768px) and (max-width:1023px) {
                        width: 110px;
                    } 
                }
            }
            .right-btn {
                display: grid;
                grid-gap: 20px;
                grid-template-columns: 1fr auto;
                justify-items: end;
                @media screen and (min-width:320px) and (max-width:767px) {
                    grid-template-rows: 1fr 1fr;
                    grid-template-columns: 1fr;
                    justify-items: end;
                }
                @media screen and (min-width:768px) and (max-width:1023px) {
                    grid-gap: 10px;
                }
                button {
                    @media screen and (min-width:320px) and (max-width:767px) {
                        width: 100%;
                    } 
                    @media screen and (min-width:768px) and (max-width:1023px) {
                        width: 110px;
                    } 
                }
            }
        }
    }
}
